import * as React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import BannerBG from "../images/banner-vector.png"

const BannerWrapper = styled.section`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin: 0 0 2rem;
  padding-bottom: 2rem;
  background-image: url(${BannerBG});
  background-position: top;
  background-size: auto 60%;
  background-repeat: no-repeat;
  border-bottom: 1px solid ${props => props.theme.colors.primary};

  .gatsby-image-wrapper {
    margin-bottom: 1rem;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    background-size: cover;
    justify-content: center;
    padding: 0 3rem;
    min-height: 50vh;
    .gatsby-image-wrapper {
      /* width: 80%; */
      height: 100%;
    }
  }
  @media (min-width: 1024px) {
    .gatsby-image-wrapper {
      max-width: 600px;
      height: 100%;
      margin: 2rem 0;
    }
  }
`
const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h5 {
    width: 80%;
    margin: 0.5rem auto 1rem;
  }
  @media (min-width: 768px) {
    width: 50vw;
    align-items: flex-start;
    h5 {
      text-align: left;
      margin: 1rem 0;
      font-size: ${props => props.theme.fontSize.h6};
    }
  }
  @media (min-width: 1024px) {
    width: 30%;
    h5 {
      font-size: ${props => props.theme.fontSize.h5};
    }
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  a {
    padding: 0.5rem 1rem;
    font-size: ${props => props.theme.fontSize.h6};
  }

  .dr-btn {
    background: ${props => props.theme.colors.primary};
    margin-right: 1.5rem;
  }

  .staff-btn {
    background: ${props => props.theme.colors.accent};
  }
  @media (min-width: 768px) {
    flex-direction: column;
    /* align-items: center; */

    .dr-btn {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }
  @media (min-width: 1024px) {
    flex-direction: row;
    /* align-items: center; */

    .dr-btn {
      margin-right: 1rem;
      margin-bottom: 0rem;
    }
  }
`

const DoctorSpotlight = styled.section``

const DoctorSpotlightMain = styled.div`
  display: grid;
  grid-template-areas:
    "title"
    "image"
    "content";

  .dr-spotlight-image {
    grid-area: image;
    margin: 0.5rem auto;
  }
  h2 {
    grid-area: title;
    text-align: center;
  }

  @media (min-width: 768px) {
    grid-template-areas:
      "title content"
      "image content";
    grid-template-columns: 1fr 2fr;
    width: 90%;
    margin: 4rem auto;

    h2 {
      text-align: left;
      align-self: end;
    }
  }
  @media (min-width: 1024px) {
    grid-template-areas:
      "title title"
      "image content";

    h2 {
      text-align: center;
      align-self: center;
      margin-bottom: 2rem;
    }
  }
`
const DoctorContent = styled.div`
  grid-area: content;
  /* width: 90%; */
  margin: 0 auto;
  padding: 0 1.6rem;

  h2 {
    font-size: ${props => props.theme.fontSize.h4};
  }
  h6 {
    text-align: center;
    margin: 0.6rem 0;
  }

  @media (min-width: 768px) {
    padding-right: 0;
    h2,
    h6 {
      text-align: left;
    }
  }
  @media (min-width: 1024px) {
    h2 {
      margin-bottom: 1rem;
    }
  }
`
const DoctorSpotlightAreas = styled.div`
  color: white;

  .spotlight-1,
  .spotlight-3 {
    background: ${props => props.theme.colors.primary};
  }
  .spotlight-2 {
    background: ${props => props.theme.colors.secondary};
  }

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`
const SpotlightArea = styled.div`
  padding: 1.6rem;

  h4 {
    margin-bottom: 0.5rem;
  }

  @media (min-width: 768px) {
    h4 {
      font-size: ${props => props.theme.fontSize.h5};
    }
  }
`
const StaffSpotlight = styled.section`
  padding: 2rem 1.6rem;
  background: ${props => props.theme.colors.lightGray};

  h2 {
    text-align: center;
    font-size: ${props => props.theme.fontSize.h3};
  }
  h5 {
    margin: 1rem 0 7rem;
    text-align: center;
  }
  @media (min-width: 768px) {
    width: 100%;
  }
`
const StaffContent = styled.div`
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
  }
  @media (min-width: 1248px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 1200px;
    margin: 0 auto;
  }
`
const StaffCard = styled.div`
  box-shadow: ${props => props.theme.boxShadow.primary};
  background: white;
  border-radius: 3px;
  padding: 1rem;
  margin-bottom: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    text-align: center;
  }

  h6 {
    margin: 0.5rem 0;
    text-align: center;
  }
  .gatsby-image-wrapper {
    margin: -6rem 0 1rem;
    border-radius: 50%;
    border: 4px solid ${props => props.theme.colors.primary};
    width: 160px;
    height: 160px;
  }
`

const StaffPage = ({ pageContext: { allStaff }, data }) => {
  return (
    <Layout>
      <Seo title="Staff" />
      <BannerWrapper>
        <BannerContent>
          <h1>Our Team</h1>
          <h5>
            Quantum Functional Medicine is a center for health and rejuvenation.
          </h5>
          <ButtonWrapper>
            <a href="#drWinkler" className="btn dr-btn">
              Dr. Winkler
            </a>
            <a href="#quantumStaff" className="btn staff-btn">
              Quantum Staff
            </a>
          </ButtonWrapper>
        </BannerContent>
        <StaticImage
          src="../images/all-staff.png"
          width={600}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Quantum Staff"
        />
      </BannerWrapper>
      <DoctorSpotlight id="drWinkler">
        <DoctorSpotlightMain>
          <StaticImage
            src="../images/dr-winkler.jpg"
            width={260}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Quantum Staff"
            layout="fixed"
            className="dr-spotlight-image"
          />
          <h2>Meet the Doctor</h2>
          <DoctorContent>
            <h2>Juergen Winkler</h2>
            <h6>MD, ABIHM, ABOIM</h6>
            <p>
              Juergen Winkler, MD, ABIHM is presently practicing at Quantum
              Functional Medicine in Carlsbad, CA, which he founded in July of
              2012.
            </p>
            <p>
              {" "}
              In 2005 he was the co-founder of Genesis Health Systems
              (Integrative Cancer and Medical Treatment Center) located in
              Oceanside, CA.
            </p>
            <p>
              He has been a featured speaker for: the NSCC Women’s Health
              Seminar, Annual IPT/IPTLD Integrative Cancer Care Conference
              (Multiple years), Health Freedom Expo 2011 & 2012, the Japanese
              Society of Oxidative Medicine in Osaka Japan, ACOSPM 2010 & 2011
              conferences, NSCC Health and Wellness Series 2013, and various
              other events.
            </p>

            <p>
              He is the physician author of Chapter 5 in the Defeat Cancer book,
              and has been a featured physician in the Townsend Letter.
            </p>
          </DoctorContent>
        </DoctorSpotlightMain>
        <DoctorSpotlightAreas>
          <SpotlightArea className="spotlight-1">
            <StaticImage
              src="../images/education-icon.png"
              width={75}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Education Icon"
            />
            <h4>Education</h4>
            <p>
              -1984 Bachelor of Arts/Chemistry from State University of New York
              (Binghamton, NY)
            </p>
            <p>
              -1988 Doctor of Medicine degree from University of Maryland
              (Baltimore, MD)
            </p>
          </SpotlightArea>
          <SpotlightArea className="spotlight-2">
            <StaticImage
              src="../images/certification-icon.png"
              width={75}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Certifications Icon"
            />
            <h4>Certifications</h4>
            <p>-2016 American Board of Physician Specialties</p>
            <p>-Diplomat in the specialty of Integrative Medicine, ABOIM</p>
            <p>
              -2015 Board Certification in Holistic and Integrative Medicine,
              ABIHM
            </p>
            <p>-Diplomat National Board of Medical Examiners (November 1989)</p>
          </SpotlightArea>
          <SpotlightArea className="spotlight-3">
            <StaticImage
              src="../images/affiliations-icon.png"
              width={75}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Professional Affiliations Icon"
            />
            <h4>Professional Affiliations</h4>
            <p>-ACAM American College for Advancement in Medicine</p>
            <p>-IFM Institute of Functional Medicine</p>
            <p>-AOA American Ozone Association</p>
            <p>
              -IOICP International Organization of Integrative -Cancer
              Physicians * Board of Medical Advisors
            </p>
            <p>
              -ACOSPM American College of Osteopathic -Sclerotherapeutic Pain
              Management
            </p>
          </SpotlightArea>
        </DoctorSpotlightAreas>
      </DoctorSpotlight>
      <StaffSpotlight id="quantumStaff">
        <h2>Meet the Staff</h2>
        <h5>
          Our staff is composed of ten amazing specialists that will help you
          take control of your health.
        </h5>
        <StaffContent>
          {allStaff &&
            allStaff.map(staff => {
              const imageData = data.allFile.edges.filter(
                img => img.node.relativePath === staff.img
              )

              if (imageData.length !== 0) {
                const image = getImage(imageData[0].node)

                return (
                  <StaffCard key={staff.name}>
                    <GatsbyImage
                      image={image}
                      alt={staff.name}
                      className="staff-spotlight-image"
                    />
                    <h4>{staff.name}</h4>
                    <h6>{staff.position}</h6>
                    <p>{staff.bio}</p>
                  </StaffCard>
                )
              } else {
                return null
              }
            })}
        </StaffContent>
      </StaffSpotlight>
    </Layout>
  )
}

export default StaffPage

export const pageQuery = graphql`
  query StaffImageQuery {
    allFile(filter: { sourceInstanceName: { eq: "staff-images" } }) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(
              width: 160
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`
